import dayjs from 'dayjs';
import RelativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import ru from 'dayjs/locale/ru';

dayjs.locale(ru);
dayjs.extend(RelativeTime);
dayjs.extend(utc);

export function formatDateTime(val) {
  return dayjs(val).format('DD.MM.YYYY, HH:mm');
}

export function formatDateMonth(val, year = false) {
  return year ? dayjs(val).format('DD MMMM YYYY') : dayjs(val).format('DD MMMM');
}

export function formatDate(val) {
  return dayjs(val).format('DD.MM.YYYY');
}

export function formatTime(val) {
  return dayjs.utc(val).local().format('HH:mm');
}
export function fromNowLocal(val) {
  return dayjs.utc(val).local().fromNow();
}

export function fromNow(val) {
  return dayjs(val).fromNow();
}

export function monthName(val) {
  return dayjs(val).format('MMMM');
}

export function isAfter(val) {
  return dayjs().isAfter(dayjs(val));
}

export function isBefore(val) {
  return dayjs().isBefore(dayjs(val));
}
